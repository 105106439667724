<template>
  <div>
    <a-table class="offset-lg attributes" :columns="columns" :data-source="attributes"
             :default-expand-all-rows="false" :expanded-row-keys="expandedRowKeys"
             @expand="onExpandChange" :indent-size="25"
             row-key="id" children-column-name="attributes" :pagination="{ hideOnSinglePage: true, pageSize: 999 }"
             :locale="{ emptyText: 'Нет данных' }">
      <template #expandIcon="{ expanded, onExpand, record, expandable }">
        <right-outlined v-if="expandable" :rotate="expanded ? 90 : 0" @click="onExpand(record)"
                        :style="{ marginRight: '0.5rem' }"/>
      </template>
      <template #attributeName="{ record }">
        <a-input v-model:value="record.name"
                 :disabled="record?.importFromObjectType"
                 :placeholder="record?.attributes !== undefined ? 'Группа атрибутов' : 'Атрибут'" class="w-100 importFromObjectType">
          <template #suffix v-if="record?.importFromObjectType">
            <a-tooltip title="Атрибут наследуется из типа объекта">
              <lock-outlined/>
            </a-tooltip>
          </template>
        </a-input>
      </template>
      <template #values="{ record }">
        <a-row :gutter="16" v-if="record?.type !== 'group'">
          <a-col :xl="{ span: 14 }" :md="{ span: 24 }" class="attributeTypeSelect">
            <a-select v-model:value="record.attributeType"
                      placeholder="Выберите вариант" size="large" class="w-100"
                      :disabled="record?.importFromObjectType"
                      @select="() => record.value = ''">
              <a-select-option :value="option.id"
                               v-for="(option) in [{title:'Число', id: 'number'}, {title:'Строка', id: 'string'}]"
                               :key="option.id">
                {{ option.title }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :xl="{ span: 10 }" :md="{ span: 24 }">
            <a-input v-model:value="record.value" placeholder="Значение" class="w-100"
                     :type="record.attributeType === 'number' ? 'number' : 'text'"/>
          </a-col>
        </a-row>
      </template>
      <template #actionsTitle>
        <a-button type="dashed" @click="addAttribute()">
          <template #icon>
            <plus-circle-outlined/>
          </template>
          Добавить атрибут
        </a-button>
        <a-button type="dashed" @click="addGroup">
          <template #icon>
            <plus-circle-outlined/>
          </template>
          Добавить группу
        </a-button>
      </template>
      <template #actions="{ record, index }">
        <a-dropdown :trigger="['click']">
          <template #overlay>
            <a-menu>
              <a-menu-item key="add_child" @click="addAttribute(record)"
                           v-if="record?.parentIndex === -1 && record?.attributes">
                <plus-circle-outlined/>
                Добавить атрибут в группу
              </a-menu-item>
              <a-menu-item key="move_up" @click="moveUp(record)"
                           :disabled="index === 0 || record?.importFromObjectType">
                <up-outlined/>
                Переместить вверх
              </a-menu-item>
              <a-menu-item key="move_down" @click="moveDown(record)"
                           :disabled="index === record.totalChild -1 || record?.importFromObjectType">
                <down-outlined/>
                Переместить вниз
              </a-menu-item>
              <a-menu-item key="delete" @click="deleteAttribute(record)"
                           :disabled="record?.importFromObjectType">
                <delete-outlined/>
                Удалить
              </a-menu-item>
            </a-menu>
          </template>
          <a-button key="more" size="large" type="dashed">
            <more-outlined/>
          </a-button>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { nextTick, onMounted, watch } from "@vue/runtime-core";
import { numberHelper } from "@/compositions/commonFunctions";
import {
  DeleteOutlined, LockOutlined,
  MoreOutlined, PlusCircleOutlined,
  DownOutlined, UpOutlined, RightOutlined
} from '@ant-design/icons-vue';
import { notification } from "ant-design-vue";

export default {
  name: 'AttributesEditor',
  props: {
    modelValue: Array,
  },
  emits: ['load'],
  setup(props, { emit }) {
    const expandedRowKeys = ref([]);

    const propsModel = computed(() => props.modelValue);
    const attributes = computed({
      get: () => props.modelValue,
      set: async (newValue) => {
        nextTick(() => emit('update:modelValue', newValue));
      }
    });

    onMounted(() => {
      loadData();
    });

    function loadData() {
      expandedRowKeys.value = [];
      attributes.value.forEach((node) => {
        expandedRowKeys.value.push(node.id);
      });
      addServiceInformationToNodes();
      emit('load');
    }

    watch(propsModel, () => {
      loadData();
    });

    // Аттрибуты
    const columns = [
      {
        title: 'Атрибут',
        className: 'col-attribute-name',
        slots: { customRender: 'attributeName' },
        width: 240,
      },
      {
        title: 'Тип поля и значение по умолчанию',
        key: 'values',
        minWidth: 300,
        slots: { customRender: 'values' },
      },
      {
        className: 'text-right actionsTitle',
        // width: 420,
        slots: { customRender: 'actions', title: 'actionsTitle' },
      },
    ];

    function addServiceInformationToNodes() {
      console.log('fix indexes');
      attributes.value.forEach((record, rowIndex) => {
        record.index = rowIndex;
        record.totalChild = attributes.value.length;
        record.parentIndex = -1;
        if (record?.attributes?.length > 0) {
          record.attributes = record.attributes.map((item, index) => ({
            ...item,
            index,
            totalChild: record?.attributes?.length,
            parentIndex: rowIndex,
          }));
        }
      })
    }

    function addGroup() {
      console.log('add group');
      attributes.value.push({
        id: numberHelper.uuid(),
        type: 'group',
        name: '',
        value: '',
        attributes: [],
      });
      addServiceInformationToNodes();
    }

    function addAttribute(parentNode) {
      let nodeToInsert = attributes.value;
      if (parentNode) {
        nodeToInsert = attributes.value[parentNode.index].attributes;
        const indexAlreadyUsed = expandedRowKeys.value.indexOf(parentNode.id);
        if (indexAlreadyUsed === -1) {
          expandedRowKeys.value.push(parentNode.id);
        }
      }
      nodeToInsert.push({
        id: numberHelper.uuid(),
        name: '',
        type: 'attribute',
        attributeType: 'number',
        value: '',
      });
      addServiceInformationToNodes();
    }

    function getParent(record) {
      return (record.parentIndex >= 0) ? attributes.value[record.parentIndex].attributes : attributes.value;
    }

    function moveUp(record) {
      if (record.index > 0) {
        const parent = getParent(record);
        // Проверим, что верхний объект не зафиксирован
        if (parent[record.index - 1]?.importFromObjectType) {
          return notification.info({
            message: 'Нельзя поместить выше',
            description: 'Наследуемые атрибуты всегда выше остальных',
          });
        }
        [parent[record.index - 1], parent[record.index]] = [parent[record.index], parent[record.index - 1]];
      }
      addServiceInformationToNodes();
    }

    function moveDown(record) {
      if (record.index < record.totalChild) {
        const parent = getParent(record);
        [parent[record.index + 1], parent[record.index]] = [parent[record.index], parent[record.index + 1]];
      }
      addServiceInformationToNodes();
    }

    function deleteAttribute(record) {
      const parent = getParent(record);
      parent.splice(record.index, 1);
      addServiceInformationToNodes();
    }

    function onExpandChange(expanded, node) {
      if (expanded) {
        expandedRowKeys.value.push(node.id);
      } else {
        const indexToDelete = expandedRowKeys.value.indexOf(node.id);
        if (indexToDelete > -1) {
          expandedRowKeys.value.splice(indexToDelete, 1);
        }
      }
    }

    return {
      attributes, columns,
      expandedRowKeys, onExpandChange,
      addAttribute, addGroup, moveUp, moveDown, deleteAttribute
    }
  },
  components: {
    LockOutlined,
    MoreOutlined,
    PlusCircleOutlined,
    DeleteOutlined,
    UpOutlined,
    DownOutlined,
    RightOutlined
  },
}
</script>

<style lang="less">

    td.col-attribute-name {
      display: flex;
      align-items: center;
    }
    .attributes {
      .attributeTypeSelect {
        @media (max-width: 1180px) {
          margin-bottom: 8px;
        }
      }
      .ant-table-thead tr th .ant-btn {
        @media (max-width: 1180px) {
          margin-left: 0px;
        }
      }
      .actionsTitle {
        width: 420px;
        @media (max-width: 1180px) {
          width: auto;
        }
      }

      .importFromObjectType {
        margin-bottom: 3px;
        @media (max-width: 1180px) {
          margin-bottom: 50px;
        }
      }

    }

</style>
